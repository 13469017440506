import { Box, Button } from '@mui/material'
import { CoreTypography, LeafTextField } from '@thriveglobal/thrive-web-leafkit'
import { useCallback } from 'react'
import { defineMessage, useIntl } from 'react-intl'

const STEPS_INPUT_LABEL = defineMessage({
    defaultMessage: 'Steps',
    description: 'input label for editing steps count'
})

const RESET_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Reset step count',
    description: 'button label for editing steps count'
})

type ActivityStepsEditorProps = {
    calculatedActivity: number
    manualSteps?: number
    setManualSteps: React.Dispatch<React.SetStateAction<number | undefined>>
}

const ActivityStepsEditor: React.FC<ActivityStepsEditorProps> = ({
    calculatedActivity,
    manualSteps,
    setManualSteps
}) => {
    const { formatMessage } = useIntl()

    const handleChangeSteps = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = Math.max(0, Number(event.target.value))
            setManualSteps(value)
        },
        [setManualSteps]
    )

    const handleResetSteps = useCallback(() => {
        setManualSteps(undefined)
    }, [setManualSteps])

    return (
        <Box display="flex" alignItems="center" gap={2}>
            <LeafTextField
                aria-label={formatMessage(STEPS_INPUT_LABEL)}
                id="activity-steps-input"
                variant="outlined"
                type="number"
                label={formatMessage(STEPS_INPUT_LABEL)}
                value={(manualSteps ?? calculatedActivity).toString()}
                fullWidth
                onChange={handleChangeSteps}
            />
            {manualSteps !== undefined && (
                <Box paddingTop={3} flexShrink={0}>
                    <Button onClick={handleResetSteps}>
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(RESET_BUTTON_TEXT)}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
        </Box>
    )
}

export default ActivityStepsEditor
