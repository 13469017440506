import {
    Button,
    Collapse,
    DialogActions,
    Stack,
    Tabs,
    useMediaQuery
} from '@mui/material'
import { format } from '@thriveglobal/thrive-web-core'
import {
    CoreAlert,
    CoreTab,
    CoreTypography,
    LeafDialog,
    LoadingButton,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { Avo } from '@thriveglobal/thrive-web-tracking'
import React, { memo, useCallback, useMemo, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { useSaveActivityEntryMutation } from '../../hooks'
import { ManualActivity } from '../../hooks/useManualActivities/useManualActivities'
import { StaticDialogContent } from '../StaticDialogContent'
import ActivityCount from './ActivityCount'
import ActivityStepsEditor from './ActivityStepsEditor'
import AddActivityCard from './AddActivityCard'

const DIALOG_TITLE_ADD_ACTIVITY = defineMessage({
    defaultMessage: 'Add activity',
    description: 'title for the add activity modal'
})

const ADD_ACTIVITY_DIALOG_DESCRIPTION = defineMessage({
    defaultMessage:
        'Select an activity and duration below to automatically convert it to activity points.',
    description: 'description for the add activity modal'
})

const WHAT_ARE_ACTIVITY_POINTS_TITLE = defineMessage({
    defaultMessage: 'What are activity points?',
    description: 'What are activity points title'
})

const WHAT_ARE_ACTIVITY_POINTS_MESSAGE = defineMessage({
    defaultMessage:
        'Activity points are calculated by combining your reported daily activities. For step and step-based activities, we calculate that 1 step = 1 activity point. For non-step activities (i.e. swimming), Thrive calculates points based on Metabolic Equivalent of Task (METS).',
    description: 'explanation of activity points'
})

const SAVE_ERROR = defineMessage({
    defaultMessage:
        'Could not save changes. Try again and if the issue persists, please report this as a bug.',
    description: 'error message for the manual activity modal'
})

const CANCEL_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Cancel',
    description: 'cancel manual activity button'
})

const ADD_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Add activity',
    description: 'add manual activity button'
})

export interface AddManualActivityModalProps {
    open: boolean
    startDate: Date
    onClose: () => void
    onAddActivity: (steps: number, isToday?: boolean) => void
}

const AddManualActivityModal: React.FC<AddManualActivityModalProps> = ({
    open,
    startDate,
    onClose,
    onAddActivity
}) => {
    const theme = useTheme()
    const { formatMessage } = useIntl()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    const [selectedTab, setSelectedTab] = useState(0)
    const [selectedActivity, setSelectedActivity] = useState<ManualActivity>()
    const [activityDate, setActivityDate] = useState(format(new Date()))
    const [activityDuration, setActivityDuration] = useState(0)
    const [mutationError, setMutationError] = useState<string>()
    const [manualSteps, setManualSteps] = useState<number>()

    const { calculatedActivity, invalidInput } = useMemo(() => {
        const calculated =
            (selectedActivity?.stepsPerMin || 0) * activityDuration
        return {
            calculatedActivity: manualSteps || calculated,
            invalidInput: calculated > 32000
        }
    }, [selectedActivity?.stepsPerMin, activityDuration, manualSteps])

    const [saveActivityEntryMutation, { loading }] =
        useSaveActivityEntryMutation({
            variables: {
                activityEntry: {
                    date: activityDate,
                    steps: calculatedActivity,
                    durationMins: activityDuration,
                    label: selectedActivity?.description
                }
            }
        })

    const isToday = useMemo(
        () => format(new Date()) === activityDate,
        [activityDate]
    )

    const handleOnClose = useCallback(() => {
        onClose()
        setSelectedActivity(undefined)
        setActivityDate(format(new Date()))
        setActivityDuration(0)
        setManualSteps(undefined)
    }, [onClose])

    const handleSetSelectedActivity = (manualActivity?: ManualActivity) => {
        setSelectedActivity(manualActivity)
        setManualSteps(undefined)
        setMutationError(undefined)
    }

    const handleSetActivityDate = (date: string) => {
        setActivityDate(date)
        setMutationError(undefined)
    }

    const handleSetActivityDuration = (duration: number) => {
        setActivityDuration(duration)
        setManualSteps(undefined)
        setMutationError(undefined)
    }

    const trackAddActivity = useCallback(() => {
        Avo.activityAdded({
            activityDate: activityDate,
            durationInMinutes: activityDuration,
            manuallyUpdatedSteps: !!manualSteps,
            numberOfActivities: 1,
            stepCount: calculatedActivity
        })
    }, [activityDate, activityDuration, calculatedActivity, manualSteps])

    const handleOnSave = useCallback(() => {
        saveActivityEntryMutation()
            .then(() => {
                trackAddActivity()
                onAddActivity(calculatedActivity, isToday)
                handleOnClose()
            })
            .catch(() => {
                setMutationError(formatMessage(SAVE_ERROR))
            })
    }, [
        calculatedActivity,
        formatMessage,
        handleOnClose,
        isToday,
        onAddActivity,
        saveActivityEntryMutation,
        trackAddActivity
    ])
    const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
        setSelectedTab(newValue)
    }

    const tabs = [
        {
            label: formatMessage(DIALOG_TITLE_ADD_ACTIVITY),
            value: 0
        },
        {
            label: formatMessage(WHAT_ARE_ACTIVITY_POINTS_TITLE),
            value: 1
        }
    ]

    return (
        <LeafDialog
            dialogTitle={formatMessage(DIALOG_TITLE_ADD_ACTIVITY)}
            open={open}
            maxWidth="md"
            fullWidth
            fullScreen={isMobile}
            onClose={handleOnClose}
        >
            <StaticDialogContent sx={{ paddingTop: 3, paddingBottom: 3 }}>
                <Tabs value={selectedTab} onChange={handleChangeTab}>
                    {tabs.map((t) => (
                        <CoreTab
                            value={t.value}
                            key={t.label}
                            aria-label={`open ${t.label} tab`}
                            label={t.label}
                        />
                    ))}
                </Tabs>
                <Stack
                    gap={2}
                    paddingTop={3}
                    minHeight={!isMobile ? 590 : undefined}
                >
                    {selectedTab === 0 && (
                        <Stack gap={3}>
                            <CoreTypography
                                variant="body1"
                                color="text.secondary"
                            >
                                {formatMessage(ADD_ACTIVITY_DIALOG_DESCRIPTION)}
                            </CoreTypography>
                            <Stack gap={2} width="100%" maxWidth={500}>
                                <AddActivityCard
                                    startDate={startDate}
                                    selectedActivity={selectedActivity}
                                    activityDate={activityDate}
                                    activityDuration={activityDuration}
                                    invalidInput={invalidInput}
                                    setSelectedActivity={
                                        handleSetSelectedActivity
                                    }
                                    setActivityDate={handleSetActivityDate}
                                    setActivityDuration={
                                        handleSetActivityDuration
                                    }
                                />
                                {selectedActivity?.isCoreStep && (
                                    <ActivityStepsEditor
                                        calculatedActivity={calculatedActivity}
                                        manualSteps={manualSteps}
                                        setManualSteps={setManualSteps}
                                    />
                                )}
                                <ActivityCount
                                    invalid={invalidInput}
                                    calculatedActivity={calculatedActivity}
                                />
                                <Collapse
                                    in={!!mutationError}
                                    mountOnEnter
                                    unmountOnExit
                                >
                                    <CoreAlert
                                        severity="error"
                                        aria-live="polite"
                                    >
                                        <CoreTypography
                                            variant="body1"
                                            color="error.main"
                                        >
                                            {mutationError}
                                        </CoreTypography>
                                    </CoreAlert>
                                </Collapse>
                            </Stack>
                        </Stack>
                    )}
                    {selectedTab === 1 && (
                        <CoreTypography variant="body1">
                            {formatMessage(WHAT_ARE_ACTIVITY_POINTS_MESSAGE)}
                        </CoreTypography>
                    )}
                </Stack>
            </StaticDialogContent>
            <DialogActions>
                <Stack
                    direction={isMobile ? 'column' : 'row'}
                    width="100%"
                    gap={1}
                    justifyContent={isMobile ? 'center' : 'flex-end'}
                >
                    <Button
                        color="secondary"
                        onClick={handleOnClose}
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(CANCEL_BUTTON_TEXT)}
                        </CoreTypography>
                    </Button>
                    <LoadingButton
                        loading={loading}
                        fixWidth={true}
                        disabled={
                            loading ||
                            !calculatedActivity ||
                            !activityDate ||
                            invalidInput
                        }
                        color="primary"
                        onClick={handleOnSave}
                        variant="contained"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {formatMessage(ADD_BUTTON_TEXT)}
                        </CoreTypography>
                    </LoadingButton>
                </Stack>
            </DialogActions>
        </LeafDialog>
    )
}
export default memo(AddManualActivityModal)
