import { ButtonProps } from '@mui/material'
import { CoreTypography, LoadingButton } from '@thriveglobal/thrive-web-leafkit'
import { FC, useCallback, useMemo, useState } from 'react'
import { defineMessage, useIntl } from 'react-intl'
import { useAppleHealthAccessStatus, useGetWearablesUser } from '../../hooks'
import WellBeingAppsDialog from '../WellBeingAppsDialog/WellBeingAppsDialog'
import { PrivacyModal } from '../PrivacyModal'
import { Avo } from '@thriveglobal/thrive-web-tracking'

const DEFAULT_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Connect',
    description:
        'label for a button that allows users to connect a wearable device'
})

const MANAGE_DEVICES_BUTTON_TEXT = defineMessage({
    defaultMessage: 'Manage devices',
    description:
        'label for a button that allows users to manage their connected wearable devices'
})

type WearablesNativeConnectButtonProps = ButtonProps & {
    buttonText?: string
    dialogOpen?: boolean
    onConnectClicked?: () => void
    onPrivacyModalAcknowledged?: () => void
}

const WearablesNativeConnectButton: FC<WearablesNativeConnectButtonProps> = ({
    buttonText,
    dialogOpen = false,
    onConnectClicked,
    onPrivacyModalAcknowledged,
    ...buttonProps
}) => {
    const intl = useIntl()

    const [isConnectDialogOpen, setIsConnectDialogOpen] = useState(dialogOpen)
    const [isPrivacyModalOpen, setIsPrivacyModalOpen] = useState(false)
    const [isPrivacyModalAcknowledged, setIsPrivacyModalAcknowledged] =
        useState(false)

    const { hasGrantedSleepAccess, hasGrantedStepsAccess } =
        useAppleHealthAccessStatus()

    const {
        hasAnyActiveConnection,
        isReturningUser,
        isLoading: isLoadingUser
    } = useGetWearablesUser()

    const hasConnectedDevice = useMemo(
        () =>
            hasAnyActiveConnection ||
            hasGrantedSleepAccess ||
            hasGrantedStepsAccess,
        [hasAnyActiveConnection, hasGrantedSleepAccess, hasGrantedStepsAccess]
    )

    const showPrivacyModalOnOpen = useMemo(
        () => !isPrivacyModalAcknowledged && !isReturningUser,
        [isPrivacyModalAcknowledged, isReturningUser]
    )

    const handleConnectClick = useCallback(() => {
        if (showPrivacyModalOnOpen) {
            setIsPrivacyModalOpen(true)
            onConnectClicked?.()
        } else {
            setIsConnectDialogOpen(true)
        }
        if (hasConnectedDevice) {
            Avo.promptSelected({
                activityType: 'manage_devices_selected',
                contentFormatType: null,
                contentId: null,
                contentSubtype: null,
                contentTitle: null,
                contentType: null,
                contentUrl: null,
                displayText: MANAGE_DEVICES_BUTTON_TEXT.defaultMessage,
                featureType: 'challenge',
                isOnboarding: false,
                resetId: null,
                resetName: null,
                checkInDate: null,
                contentSource: null,
                isAutomaticCheckin: null,
                microstepBody: null,
                microstepId: null,
                microstepName: null,
                notificationCount: null,
                tabName: null,
                tstPlatform: null,
                userId_: ''
            })
        }
    }, [showPrivacyModalOnOpen, hasConnectedDevice, onConnectClicked])

    const handleShowPrivacyModal = useCallback(() => {
        setIsPrivacyModalOpen(true)

        Avo.promptSelected({
            activityType: 'device_privacy_info_selected',
            contentFormatType: null,
            contentId: null,
            contentSubtype: null,
            contentTitle: null,
            contentType: null,
            contentUrl: null,
            displayText: MANAGE_DEVICES_BUTTON_TEXT.defaultMessage,
            featureType: 'challenge',
            isOnboarding: false,
            resetId: null,
            resetName: null,
            checkInDate: null,
            contentSource: null,
            isAutomaticCheckin: null,
            microstepBody: null,
            microstepId: null,
            microstepName: null,
            notificationCount: null,
            tabName: null,
            tstPlatform: null,
            userId_: ''
        })
    }, [])

    const handleClosePrivacyModal = useCallback(() => {
        setIsPrivacyModalOpen(false)
    }, [])

    const handleCloseConnectDialog = useCallback(() => {
        setIsConnectDialogOpen(false)
    }, [setIsConnectDialogOpen])

    const handleIsPrivacyModalAcknowledged = useCallback(
        (isAcknowledged: boolean) => {
            setIsPrivacyModalAcknowledged(isAcknowledged)
            if (isAcknowledged) {
                setIsConnectDialogOpen(true)
                onPrivacyModalAcknowledged?.()
            }
        },
        [onPrivacyModalAcknowledged]
    )

    return (
        <>
            {isConnectDialogOpen && (
                // HACK: normally dialog state is handled with an `open` prop but resetting the dialog state on close (to not show the apple health section)
                // didn't quite work as the original state would flash before closing, thus we're unmounting completely on close
                <WellBeingAppsDialog
                    onClose={handleCloseConnectDialog}
                    onShowPrivacyModal={handleShowPrivacyModal}
                />
            )}

            <PrivacyModal
                open={isPrivacyModalOpen}
                onClose={handleClosePrivacyModal}
                setPrivacyModalAcknowledged={handleIsPrivacyModalAcknowledged}
            />
            <LoadingButton
                data-testid="wearables-native-connect-button"
                onClick={handleConnectClick}
                variant="contained"
                color="secondary"
                sx={{ display: 'flex', alignItems: 'center' }}
                loading={isLoadingUser}
                fixWidth={true}
                {...buttonProps}
            >
                <CoreTypography customVariant="buttonNormal">
                    {buttonText ??
                        intl.formatMessage(
                            hasConnectedDevice
                                ? MANAGE_DEVICES_BUTTON_TEXT
                                : DEFAULT_BUTTON_TEXT
                        )}
                </CoreTypography>
            </LoadingButton>
        </>
    )
}

export default WearablesNativeConnectButton
