import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    useTheme,
    useMediaQuery
} from '@mui/material'
import { FC, PropsWithChildren } from 'react'
import { FormattedMessage as _FormattedMessage } from 'react-intl'

import {
    LeafDialog,
    CoreTypography,
    LeafIcon,
    LeafPrivacyPolicyLink,
    LeafToSLink
} from '@thriveglobal/thrive-web-leafkit'
import { ModifiedFormattedMessageType } from 'src/types/formatJs'

const FormattedMessage =
    _FormattedMessage as unknown as ModifiedFormattedMessageType

export interface PrivacyModalProps {
    open: boolean
    onClose: () => void
    setPrivacyModalAcknowledged: (isAcknowledged: boolean) => void
}

const IconContainer: FC<PropsWithChildren> = ({ children }) => {
    const theme = useTheme()
    return (
        <Box
            minWidth={80}
            minHeight={80}
            maxWidth={80}
            maxHeight={80}
            borderRadius="50%"
            sx={{
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontSize={theme.typography.pxToRem(60)}
            marginRight={3}
        >
            {children}
        </Box>
    )
}

export const PrivacyModal: FC<PrivacyModalProps> = ({
    open,
    onClose,
    setPrivacyModalAcknowledged
}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    return (
        <LeafDialog
            open={open}
            onClose={onClose}
            fullScreen={isMobile}
            data-testid="wearable-privacy-modal"
            dialogTitle={
                <>
                    <CoreTypography variant="h3" color={'text.primary'} pb={1}>
                        <FormattedMessage
                            defaultMessage="Your data privacy is important to us"
                            description="Title of the privacy modal"
                        />
                    </CoreTypography>
                    <CoreTypography variant="body1" color={'text.primary'}>
                        <FormattedMessage
                            defaultMessage="We want you to know exactly how your data is being used to make effective recommendations to your well-being journey."
                            description="Subtitle of the privacy modal"
                        />
                    </CoreTypography>
                </>
            }
        >
            <DialogContent
                sx={{
                    paddingX: isMobile ? 4 : 8,
                    paddingTop: 8,
                    paddingBottom: 0
                }}
            >
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    pt={6}
                    pb={3}
                >
                    <IconContainer>
                        <LeafIcon
                            icon={'chart-line'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="We track your progress for you"
                                description="Title of the how we collect data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="You don’t have to lift a finger. We automatically check-in to your Challenge Microsteps for you."
                                description="Description of the how we collect data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    py={3}
                >
                    <IconContainer>
                        <LeafIcon
                            icon={'eye-slash'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="Your data is safe with us"
                                description="Title of the how we use the data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Your data will be anonymized and aggregated into groups of no less than 20 people. Your anonymized data may be shared with your employer to provide trends and guide well-being strategies for your company."
                                description="Description of the how we use the data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    py={3}
                >
                    <IconContainer>
                        <LeafIcon
                            icon={'lock'}
                            fontSize={'inherit'}
                            color={'inherit'}
                        />
                    </IconContainer>
                    <Box>
                        <CoreTypography variant="h4">
                            <FormattedMessage
                                defaultMessage="You have control of your data"
                                description="Title of the how we manage the data"
                            />
                        </CoreTypography>
                        <CoreTypography variant="body1">
                            <FormattedMessage
                                defaultMessage="Choose which biometric data you’d like to share. You may request a copy of your data or for it to be deleted anytime."
                                description="Description of the how we manage the data"
                            />
                        </CoreTypography>
                    </Box>
                </Box>
                <CoreTypography
                    variant="body2"
                    color={theme.palette.grey[700]}
                    pt={3}
                    pb={6}
                >
                    <FormattedMessage
                        defaultMessage="For more information, please visit Thrive’s <policyLink>Privacy Policy</policyLink> and <termsLink>Terms of Service</termsLink>."
                        description="Terms of service information with link to the privacy policy"
                        values={{
                            policyLink: (chunk: React.ReactNode[]) => (
                                <LeafPrivacyPolicyLink>
                                    {chunk}
                                </LeafPrivacyPolicyLink>
                            ),
                            termsLink: (chunk: React.ReactNode[]) => (
                                <LeafToSLink>{chunk}</LeafToSLink>
                            )
                        }}
                    />
                </CoreTypography>
            </DialogContent>
            <DialogActions
                disableSpacing
                sx={{
                    paddingX: isMobile ? 4 : 8,
                    paddingBottom: 8,
                    paddingTop: 0,
                    display: 'flex',
                    justifyContent: 'start'
                }}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        setPrivacyModalAcknowledged(true)
                        onClose()
                    }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            defaultMessage="Sounds good"
                            description="Button text to confirm user read the privacy information"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </LeafDialog>
    )
}

export default PrivacyModal
